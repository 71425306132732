import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/ButtonWithIcon/ClientButtonWithIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/DatadogInit/ClientDatadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterBottomBlock/ClientCSR.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterBottomBlock/ClientSocialMedia.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterBottomBlock/ClientTerms.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterBottomBlock/FooterBottom.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterContactAndLinksBlock/ClientContactSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterContactAndLinksBlock/ClientMobileVersion.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterContactAndLinksBlock/ClientSection.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterContactAndLinksBlock/FooterContactAndLinks.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterNewsletterBlock/ClientFooterNewsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterNewsletterBlock/ClientFooterNewsletterContainer.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterNewsletterBlock/FooterNewsletter.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterStoreBlock/ClientFooterStore.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterStoreBlock/FooterStore.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/ExpertPanel/ExpertPanel.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterUspBlock/FooterUsp/ClientFooterLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterUspBlock/FooterUsp/ClientPanelCTA.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterUspBlock/FooterUsp/FooterUsp.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Footer/FooterUspBlock/FooterUspBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/CMSPageSections/TextAndButtonBannerV2/TextAndButtonBannerV2.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/AccountButton/ClientAccountButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/BagButton/ClientBagButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/ClientHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/LogoButton/ClientLogoButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/Menu/NavigationMenu/ClientMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/MenuButton/ClientMenuButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/WishlistButton/ClientWishlistButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/HeroBannerWithLinksBlock/ClientHeroBannerWithLinksBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroBannerLink"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/HeroBannerWithLinksBlock/HeroBannerLink/HeroBannerLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageCarousel"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/ImageCarouselBlock/ImageCarousel/ImageCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/ImageCarouselBlock/ImageCarouselBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/HomepageSections.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Header/Header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/StoryboardBlock/ClientStoryboardBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientStoryboardLink"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/HomepageSections/StoryboardBlock/StoryboardLink/ClientStoryboardLink.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/Icon/Icon.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/LocaleDisplay/ClientLocaleDisplay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/LocationBanner/ClientLocationBanner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/PriceInfo/PriceInfo.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/components/TrackingOnLoad/ClientTrackingOnLoad.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/home/runner/work/monorepo/monorepo/apps/headless-commerce/src/contexts/ClientProviders.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/.pnpm/next@14.2.21_@babel+core@7.26.0_@playwright+test@1.46.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/client/image-component.js");
